import styled, {css} from "styled-components";
import {Headline, Text} from "../../../styles/typography";
import {theme} from "../../../styles/theme";
import {device} from "../../../styles/mediaQueries";

export const StyledTabs = styled.div``;

export const TabHeader = styled.div`
  display: flex;
  gap: 3.6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  justify-content: space-evenly;

  @media ${device.tablet} {
    justify-content: space-between;
  }
`;

export const Icon = styled.img`
  opacity: 0.3;
  transition: opacity 0.3s linear;
  max-width: 10rem;
`;

export const IconWrapper = styled.div`
  position: relative;
  max-width: 8.7rem;
  width: 33.333%;
  padding: 0 0.5rem 2.5rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.3rem;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: transform 0.3s ease;
    background-color: ${theme.colors.orange};
  }

  ${props => props.isSelected && css`
    &:after {
      transform: scaleY(1); 
    }
    
    ${Icon} {
      opacity: 1;
    }
  `}

  @media ${device.tablet} {
    max-width: initial;
  }
`;

export const TabContent = styled.div`
  margin-top: 2.5rem;
  position: relative;
  width: 100%;
  
  ${Headline} {
    line-height: 1.3;
  }
  
  ${Text} {
    margin-top: 1.8rem;
  }

  @media ${device.tablet} {
    max-width: 64rem;
    margin-top: 8rem;

    ${Text} {
      margin-top: 2.1rem;
    }
  }
`;
