import styled, { css } from 'styled-components';
import {theme} from "../../../styles/theme";
import {device} from "../../../styles/mediaQueries";

const ButtonStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  border-radius: 0.3rem;

  width: ${props => props.icon ? "fit-content" : "100%"};
  background-color: ${props => props.backgroundColor || theme.colors.orange};
  color: ${props => props.textColor || theme.colors.white};

  @media ${device.tablet} {
    padding: 2.9rem;
  }
`

export const StyledButton = styled.button`
  ${ButtonStyles};
`;
