import create from 'zustand'
import arService from '../arService';
import debugService from '../debugService';
// import permissionService from '../permissionService';
import storageService from '../storageService';
import storyService from '../storyService';

type InitService = {
  initialized: boolean
  init: () => Promise<void>
};

declare const window: any;

const initService = create<InitService>((set, get) => {
  return {
    initialized: false,
    init: async () => {
      return new Promise(async (resolve, reject) => {
        try {
          debugService.getState().init()
          await storageService.getState().init()
          // await permissionService.getState().init()
          await storyService.getState().init()
          arService.getState().init()

          window.XR8 ? set({initialized: true}) : window.addEventListener('xrloaded', () => {
            initService.setState({initialized: true})
          })

          resolve()
        } catch(error) {
          reject(error)
        }
      })
    }
  }
})

export default initService