import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes, 
  Navigate
} from "react-router-dom";

import Landing from './pages/landing'
import Start from './pages/story/start';
import Ar from './pages/story/ar';
import Share from './pages/story/share';
import NotFound from './pages/notFound';
import Impressum from './pages/impressum';

import initService from "./services/initService";
import Permissions from "./components/permissions";
import OrientationInfo from "./components/orientationInfo";
import ArCanvas from "./components/arCanvas";
import PrivacyPolicy from "./pages/privacyPolicy";

const App = () => {
  const init = async () => {
    try {
      await initService.getState().init()
      console.log("App::init(): Initialized application.")
    } catch(error) {
      console.error(error)
    }
  }

  const loc = window.location || {pathname: "/"};

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <OrientationInfo />
      {/* <Permissions /> */}

      {/*Persist r3d canvas to avoid context lost on route switch*/}
      <ArCanvas />
      {/* <Router basename={loc.pathname || 'test'}> */}
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path={"/not-found"} element={<NotFound />}/>
          <Route path={"/ar/:storyUrl"} element={<Ar />}/>
          <Route path={"/share/:storyUrl"} element={<Share />}/>
          <Route path={"/impressum"} element={<Impressum />}/>
          <Route path={"/privacy-policy"}  element={<PrivacyPolicy />}/>
          <Route path={"/new-work-new-skills"} element={<Navigate replace to={"/"} />}  />      
          <Route path={"/"} element={<Start />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
