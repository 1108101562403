import create from 'zustand'

type RequiredInteraction = {
  id: number
  interacted: boolean
}

type InteractionMode = "PLACE" | "INTERACT" | "INSPECT"

type InteractionService = {
  mode: InteractionMode
  selected: number
  required: Array<RequiredInteraction>
  setInteraction: (id: number, interacted?: boolean) => boolean
  registerRequiredInteraction: (id: number) => void
  setSelected: (id: number | null) => void
  reset: () => void
};

const interactionService = create<InteractionService>((set, get) => {
  return {
    mode: "PLACE",
    selected: null,
    required: new Array<RequiredInteraction>(),
    setInteraction: (id, interacted = true) => {
      const entry: RequiredInteraction = get().required.find(entry => entry.id === id)

      if(!entry) return false

      entry.interacted = interacted

      if(interacted)
        set({mode: "INSPECT"})

      set({required: get().required})  

      return true
    },
    registerRequiredInteraction: (id) => {
      get().required.push({id, interacted: false})
    },
    setSelected: (id) => {
      set({selected: id})
    },
    reset: () => {
      set({
        mode: "PLACE",
        selected: null,
        required: new Array<RequiredInteraction>(),
      })
    }
  }
})

export default interactionService