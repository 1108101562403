import { interactive } from '../../../services/storyService'
import {FC, useEffect, useRef, useState} from 'react'
import {StyledInspect, ButtonWrapper, ContentWrapper, ScrollWrapper} from './styles';
import interactionService from '../../../services/interactionService'
import BaseImage from "../../Common/BaseImage";
import {HeadlineXl, Text} from "../../../styles/typography";
import {Button} from "../../Common/Button";
import Tabs from "../../Common/Tabs";
import BaseVideo from "../../Common/BaseVideo";
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { Console } from 'console';

interface Inspect {
  interactive: interactive
}

const Inspect: FC<Inspect> = ({ interactive }) => {
  const { mode, selected } = interactionService(state => ({
    mode: state.mode,
    selected: state.selected
  }))

  const videoRef = useRef<any>()
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  
  //Reset on inspect start as it otherwise would jump back to first frame
  if(mode === "INSPECT" && videoRef.current && selected){
    videoRef.current.pause()
    videoRef.current.currentTime = 0
  }


  const handleClose = () => {
    interactionService.getState().setSelected(null)
    interactionService.setState({mode: "INTERACT"})
  }

  const handleUpskillClick = event => {
    event.preventDefault()

    if(videoRef.current?.readyState === 4) { 
      //setIsVideoPlaying(true)
      
      videoRef.current.play()

      setTimeout(handleClose, videoRef.current.duration * 750)
    } else {
      handleClose()
    }
  }


  if(!interactive.content) return null

  return (
    <StyledInspect isVisible={mode === "INSPECT" && selected === interactive.id}>
        <ScrollWrapper>
            <ContentWrapper>
              {interactive.content.map(({type, data}) => (
                  {
                    headline: <HeadlineXl>{data}</HeadlineXl>,
                    text: <Text>{data}</Text>,
                    // @ts-ignore
                    tabs: <Tabs sections={data} />,
                    // @ts-ignore
                    image: <BaseImage {...data} />,
                    // @ts-ignore
                    video: <BaseVideo src={data.src} width={data.width} height={data.height} videoRef={videoRef} options={data} />,
                  }[type]
              ))}
            </ContentWrapper>

            {!isVideoPlaying &&             
              <ButtonWrapper>
                  <Button text={`${interactive.title} aktivieren`} onClick={handleUpskillClick} />
              </ButtonWrapper>
            }
        </ScrollWrapper>
    </StyledInspect>
  )
}

export default Inspect;
