import styled from 'styled-components'
import {HeadlineXl, Text} from "../../../styles/typography";
import {StyledButton} from "../../../components/Common/Button/styles";
import {StyledBaseImage} from "../../../components/Common/BaseImage/styles";
import BaseLayout from "../../../components/Common/BaseLayout";
import BaseImage from "../../../components/Common/BaseImage";
import {device} from "../../../styles/mediaQueries";
import {LinkStyles} from  "../../../styles/typography"

export const StyledStart = styled(BaseLayout)`
  padding: var(--page-padding);
  
  ${HeadlineXl} {
    max-width: 67rem;
  }

  ${Text} {
    margin-top: -3rem;
    line-height: 1.5;
    max-width: 63rem;
  }
  
  ${StyledButton} {
    margin: 3rem auto 0;
  }

  @media ${device.tablet} {
    ${Text} {
      margin-top: 1.7rem;
    }

    ${StyledButton} {
      margin-top: 5.2rem;
    }
  }


`;

export const ImageWrapper = styled.div`
  max-width: 53rem;
  height: auto;
`

export const StartImage = styled(BaseImage)`
  margin-top: 0.8rem;

`

export const LinksWrapper = styled.footer`
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.6rem;
  row-gap: 0.4rem;

  @media ${device.tablet} {
    column-gap: 3rem;
    row-gap: 1rem;
  }

  .navlink{
    ${LinkStyles}
  }
`;



