import { createGlobalStyle } from 'styled-components';
import { reset } from "./reset";
import {TextStyles} from "./typography";
import {device} from "./mediaQueries";
import {theme} from "./theme"

const GlobalStyle = createGlobalStyle`
  ${reset};
  
  :root {
    --page-padding: 3.2rem;
    --ease-linear: cubic-bezier(0.25,0.25,0.75,0.75);
    --ease-in-sine: cubic-bezier(0.47,0,0.745,0.715);
    --ease-out-sine: cubic-bezier(0.39,0.575,0.565,1);
    --ease-in-out-sine: cubic-bezier(0.445,0.05,0.55,0.95);
    --ease-in-quad: cubic-bezier(0.55,0.085,0.68,0.53);
    --ease-out-quad: cubic-bezier(0.25,0.46,0.45,0.94);
    --ease-in-out-quad: cubic-bezier(0.455,0.03,0.515,0.955);
    --ease-in-cubic: cubic-bezier(0.55,0.055,0.675,0.19);
    --ease-out-cubic: cubic-bezier(0.215,0.61,0.355,1);
    --ease-in-out-cubic: cubic-bezier(0.645,0.045,0.355,1);
    --ease-in-quart: cubic-bezier(0.895,0.03,0.685,0.22);
    --ease-out-quart: cubic-bezier(0.165,0.84,0.44,1);
    --ease-in-out-quart: cubic-bezier(0.77,0,0.175,1);
    --ease-in-quint: cubic-bezier(0.755,0.05,0.855,0.06);
    --ease-out-quint: cubic-bezier(0.23,1,0.32,1);
    --ease-in-out-quint: cubic-bezier(0.86,0,0.07,1);
    --ease-in-expo: cubic-bezier(0.95,0.05,0.795,0.035);
    --ease-out-expo: cubic-bezier(0.19,1,0.22,1);
    --ease-in-out-expo: cubic-bezier(1,0,0,1);
    --ease-in-circ: cubic-bezier(0.6,0.04,0.98,0.335);
    --ease-out-circ: cubic-bezier(0.075,0.82,0.165,1);
    --ease-in-out-circ: cubic-bezier(0.785,0.135,0.15,0.86);
    --ease-in-back: cubic-bezier(0.6,-0.28,0.735,0.045);
    --ease-out-back: cubic-bezier(0.25,2,0.5,0.9);
    --ease-in-out-back: cubic-bezier(0.68,-0.55,0.265,1.55);
  }

  @media ${device.tablet} {
      :root {
          --page-padding: 7rem;
      }
  }

  @font-face {
    font-family: "Helvetica";
    font-weight: 500;
    src: url(${theme.fonts.helvetica}) format("woff2");
    font-display:swap
  }

  @font-face {
    font-family: "Helvetica Bold";
    font-weight: 500;
    src: url(${theme.fonts.helveticaBold}) format("woff2");
    font-display:swap
  }

  @font-face {
    font-family: "Charter";
    font-weight: 500;
    src: url(${theme.fonts.charter}) format("woff2");
    font-display:swap
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    overscroll-behavior: none;
    font-size: 62.5%;
    height: -webkit-fill-available;
  }
  html, html a {
    -webkit-text-size-adjust: none;
    text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.004);
  }
  
  #root {
    overflow: hidden scroll;
    height: -webkit-fill-available;
    scroll-behavior: smooth;
  }
  
  body {
    font-family: Helvetica;
    height: 100%;
    min-height: -webkit-fill-available;
    overflow: hidden;
    background-color: ${theme.colors.white};


    #custom-landing-container-root {

      #custom-landing-container {

          #custom-centered-container{

            #custom-container-logo {
              width: 140px;
              position: fixed;
              top: 48px;
              left: 48px;
              }

              #custom-linkwrapper {
                background-color: #464646;
                width: 100%;
                position: fixed;
                bottom: 0;

                    #custom-linkwrapper-container {

                    ${TextStyles};
                    padding: 12px 48px;
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 1.6rem;
                    row-gap: 0.4rem;
                    justify-content: right;


                      a{
                        font-size: 1.4rem;
                        line-height: 1.3;
                        display: inline-block;
                        color: ${theme.colors.white};
                      }
                    }
              }

                }

                 #custom-model-viewer-info {
                      margin-bottom: 1rem;
                      font-size: 20px;
                      max-width:100%;

                      .landing8-prompt-section{
                            .landing8-prompt-text{
                              margin-bottom: 0.8rem;
                              font-size: 20px;
                              max-width: 50%;
                            }

                            .landing8-prompt-link{
                              font-size: 20px;
                              color: #EB8C00;
                            }

                      }

                      .landing8-logo-section{
                    
                          margin-bottom: 1.2rem;
                        }

                  
                }

                #custom-model-canvas {
                  translate(50px,-50px) scale(2);

                  }
          }

      }
    }



    #prompt-box-8w  {

        z-index: 820;
        background-color: white;
        color: #000000;
        outline: none;
        filter: none;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        text-align: left;
        padding: var(--page-padding);
        overflow-y: scroll;

        #custom-logo {
          display: inline-block;
        }

        #prompt-box-8w-headline-logo {
          display: inline-block;
          width: 6.6rem;

              @media ${device.tablet} {
                width: 12.5rem;
              }
          }

    }


    #prompt-box-8w-headline {
      /* StyledBaseLayout*/
      margin-top: 3.7rem;
  
      /* Typography Base*/
      font-family: "Charter", serif;
      font-size: 4rem;
      text-align: left;
      line-height: 1.3;
      letter-spacing: 0.04rem;

      max-width: 67rem;
  
      @media ${device.tablet} {
        font-size: 6rem;
        max-width: 90rem;

      }
      color: ${theme.colors.black};
    }


    #custom-body{
      margin: 0 auto;
      margin-top: 3.2rem;
      line-height: 1.5;
      text-align: left;

      font-family: "Helvetica", sans-serif;
      font-size: 1.5rem;
  
      @media ${device.tablet} {
        margin-top: 8.2rem;
        font-size: 2.3rem;
        line-height: 1.4;
      }
  
      color: ${theme.colors.grey};

    }

    #prompt-box-8w-body {
      max-width: 63rem;

      @media ${device.tablet} {
        align-items: left;
        max-width: 90rem;

      }
    }

      

    #prompt-button-container-8w {
      display: block;
    }

    #button-primary-8w {
      background-color: ${theme.colors.orange};
      color: #FFFFFF;
    
      padding: 1.6rem;
      border-radius: 0.3rem;
      margin: 3rem auto 0;

      position: relative;
      justify-content: center;
      align-items: center;
  
      width: 100%;
      color: ${theme.colors.white};
  
      font-family: "Helvetica Bold", sans-serif;
      font-size: 1.5rem;


      @media ${device.tablet} {
        position: fixed;
        bottom: 4.4rem;
        width: calc(100% - var(--page-padding) - var(--page-padding));
        padding: 2.9rem;
        font-size: 2.3rem;
        letter-spacing: 0.02px;
        line-height: 1;
      }
  
  
    }

    #prompt-button-8w {

    }

    #prompt-button-8w:not(:last-child) {
      max-width: 63rem;
      margin-top: 1.6rem;
      padding: 0;

      font-family: "Helvetica", sans-serif;
      font-size: 1.5rem;
      line-height: 1.3;

      background-color: #FFFFFF;
      color: ${theme.colors.grey};
      
      text-decoration: underline;
      text-align: left;
      border-radius: 0px;

      @media ${device.tablet} {
        font-size: 2.3rem;
        line-height: 1.4;
      }
  

    }
  }
`;

export default GlobalStyle;
