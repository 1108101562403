import styled from "styled-components";

export const StyledBaseVideo = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(var(--ar) * 100%);
  overflow: hidden;

  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'brightness\'><feColorMatrix type=\'matrix\' values=\'1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0\'/></filter></svg>#brightness"); /* Firefox 3.5+ */
  -webkit-filter:brightness(100%); /* Chrome 19+ & Safari 6+ */

`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`
