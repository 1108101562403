import styled, {css} from 'styled-components';
import {device} from "../../../styles/mediaQueries";

export const LogoWrapper = styled.div`
  position: fixed;
  top: 1.9rem;
  left: 1.9rem;
  z-index: 2;

  @media ${device.tablet} {
    top: var(--page-padding);
    left: var(--page-padding);
  }
`
