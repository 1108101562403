import { theme } from '../../../styles/theme';
import styled from 'styled-components';
import { device } from '../../../styles/mediaQueries';

export const LoadingContainer = styled.div`
 /* StyledBaseLayout*/
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available

  position:fixed;

  margin: 0 auto;
  background-color: ${theme.colors.white};
  padding: var(--page-padding);
  overflow-y: scroll;

  z-index: 800;

  #loadBackground {
    background-color: ${theme.colors.white};
  }

  .xrextras-old-style #loadBackground {
    background-color: ${theme.colors.white};
  }

  #requestingCameraPermissions {
    z-index: 801;
    position: fixed;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);

    width: calc(100% - 3rem);
    height: 125px;

    background-color: ${theme.colors.greyLight};

    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    
    @media ${device.tablet} {
      top: 1.5rem;
      width: 50rem;
      
    }

    #popupText {
      font-family: "Helvetica", sans-serif;
      font-size: 1.5rem;
      line-height: 1.3;
      color: ${theme.colors.grey};
      text-align: center;
      margin-top: 2rem;
        
    @media ${device.tablet} {     
      margin-top: 1.2rem;
      font-size: 2.3rem;
      line-height: 1.4;
    }

  }


  }

  #requestingCameraIcon {
    display: block;
    margin: 0 auto;
    margin-bottom: 2vh;
    height: 5.5vh;
    filter: invert(1);
  }

  #loadImage {
    position: absolute;
    margin-top: -2.5em;
    margin-left: -2.5em;
    top: 50%;
    left: 50%;
    height: 5em;
    width: 5em;
    transform: translate(-50%, -50%);
  }

  /* camera and micrphone permission related styles */
  #cameraPermissionsErrorApple,
  #microphonePermissionsErrorApple {
    background-color: ${theme.colors.white};
  }

  .xrextras-old-style #cameraPermissionsErrorApple,
  .xrextras-old-style #microphonePermissionsErrorApple {
    background-color: ${theme.colors.white};
  }



  #cameraPermissionsErrorAppleMessage,
  #permissionErrorHeadline,
  #microphonePermissionsErrorAppleMessage {
    /* StyledBaseLayout*/
    margin-top: 3.7rem;

    /* Typography Base*/
    font-family: "Charter", serif;
    font-size: 4rem;
    line-height: 1.3;
    letter-spacing: 0.04rem;

    max-width: 67rem;

    @media ${device.tablet} {
      font-size: 6rem;
      max-width: 90rem;

    }

    color: ${theme.colors.black};
  }


  #permissionErrorBody,
  #motionPermissionUserPromptErrorMessage
 {
    margin-top: 3.2rem;
    line-height: 1.5;
    max-width: 63rem;


    font-family: "Helvetica", sans-serif;
    font-size: 1.5rem;

    @media ${device.tablet} {
      margin-top: 8.2rem;
      font-size: 2.3rem;
      line-height: 1.4;
    }

    color: ${theme.colors.grey};

  }
  
  .xrextras-old-style #cameraPermissionsErrorAppleMessage,
  .xrextras-old-style #microphonePermissionsErrorAppleMessage {
    color: ${theme.colors.black};
  }

  .permissionIconIos {
    display: block;
    margin: 0 auto;
    margin-top: 17vh;
    text-align: center;
    filter: invert(1);
  }
  .permissionIconIos img {
    height: 20vw;
  }
  .permissionIconIos img + img {
    margin-left: 10vw;
  }

  .bottom-message {
    color: ${theme.colors.black};
    padding: 0 5vw;
    position: absolute;
    bottom: 10vh;
    text-align: center;
    font-size: 1.25em;
  }

  #cameraPermissionsErrorAndroid,
  #microphonePermissionsErrorAndroid {
    background-color: ${theme.colors.white};
    display: block;
  }

  .xrextras-old-style #cameraPermissionsErrorAndroid,
  .xrextras-old-style #microphonePermissionsErrorAndroid {
    background-color: ${theme.colors.white};
    display: block;

  }


  #deviceMotionErrorApple {
    padding: 3vh 2vh;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    justify-content: space-around;
    align-items: left;
    background-color: ${theme.colors.white};
    color: black;
  }

  .xrextras-old-style #deviceMotionErrorApple {
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
  }

  #linkOutViewAndroid,
  #copyLinkViewAndroid {
    background-color: #${theme.colors.black};
  }

  .xrextras-old-style #linkOutViewAndroid,
  .xrextras-old-style #copyLinkViewAndroid {
    background-color: ${theme.colors.white};
  }

  .permission-error {
    padding: 3vh 5vh;
    font-size: 3.5vh;
    color: #fff;
    background-color: ${theme.colors.black};
  }

  .xrextras-old-style .permission-error {
    color: #323232;
    background-color: ${theme.colors.white};
  }

  .permission-error > h1 {
    font-size: 1.3em;
  }

  .main-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
    border-radius: 0.3rem;

    width: 100%;
    background-color: ${theme.colors.orange};
    color: ${theme.colors.white};
    margin: 3rem auto 0;

    font-family: "Helvetica Bold", sans-serif;
    font-size: 1.5rem;


    @media ${device.tablet} {
      position: fixed;
      bottom: 4.4rem;
      width: calc(100% - var(--page-padding) - var(--page-padding));
      padding: 2.9rem;

      font-size: 2.3rem;
      letter-spacing: 0.02px;
      line-height: 1;
    }
  

  }

  .xrextras-old-style .main-button {
    background-color: ${theme.colors.red};
  }

  .start-ar-button {
    position: fixed;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Nunito-SemiBold', sans-serif;
    font-weight: 800;
    font-size: 1.5em;
    text-align: center;
    color: white;
    text-decoration: none;
    border: none;
    background-color: ${theme.colors.red};
    padding: 6px 13px;
    border-radius: 10px;
  }

  .xrextras-old-style .start-ar-button {
    background-color:  ${theme.colors.red};
  }

  .permissionIcon {
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: 2vh;
  }

  .permissionIcon img {
    display: block;
    margin: 0 auto;
    height: 5vh;
  }

  #cameraSelectionWorldTrackingError {
    z-index: 999;
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
    color: black;
    font-size: 3.7vh;
    background-color: white;
    padding: 3vh 0;
  }

  .loading-error-header {
    font-size: 3.5vh;
    flex: 0 0 auto;
    color:  ${theme.colors.black};
  }

  .xrextras-old-style .loading-error-header {
    color: #323232;
  }

  .loading-error-footer {
    font-size: 3vh;
    line-height: 5.5vh;
    flex: 0 0 auto;
    text-align: center;
    width: 80vmin;
    color: black;
  }

  .xrextras-old-style .loading-error-footer {
    color: #323232;
  }

  .loading-error-footer img {
    display: block;
    height: 5vh;
    margin: 0 auto;
    margin-bottom: 2vh;
  }

  .loading-error-instructions {
    font-family: Open-Sans, Helvetica, Sans-Serif;
    color: black;
    font-size: 2.5vh;
    list-style: none;
    margin-left: 1em;
    counter-reset: line;
    flex: 0 0 auto;
  }

  .xrextras-old-style .loading-error-instructions {
    color: #2d2e43;
  }

  .loading-error-instructions > li {
    position: relative;
    margin-bottom: 4.5vh;
  }

  .loading-error-instructions > li > img {
    max-height: 3vh;
    vertical-align: middle;
    margin: 0 0.5vh;
  }

  .loading-error-instructions > li:before {
    font-family: Open-Sans, Helvetica, Sans-Serif;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 3vh;
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    text-align: center;
    left: -8vh;
    top: -1vh;
    font-size: 2.5vh;
    line-height: 5.5vh;
    counter-increment: line;
    content: counter(line);
  }

  .xrextras-old-style .loading-error-instructions > li:before {
    background-color: ${theme.colors.white};
  }

  .highlight {
    color: ${theme.colors.white};
    y: ${theme.fonts.gothamBold}, sans-serif;
    font-weight: 800;
  }

  .xrextras-old-style .highlight {
    color: ${theme.colors.white};
  }




  .camera-instruction-block {
    display: inline-block;
    background-color: ${theme.colors.white};
    padding: 1vh;
  }

  .camera-instruction-text {
    display: inline-block;
    background-color: ${theme.colors.red};
    padding: 1vh;
  }


  .xrextras-old-style .camera-instruction-block {
    background-color: ${theme.colors.red};
  }

  .camera-instruction-button {
    display: inline-block;
    padding: 1vh;
    background-color: ${theme.colors.red};
    color: white;
    font-size: 2vh;
    box-shadow: 0 0.125vh 0.25vh rgba(0, 0, 0, 0.5);
  }

  .fade-out {
    animation: fade-out 0.3s linear forwards;
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .spin {
    animation: spin 1.1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite both;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .scale {
    -webkit-animation: scale 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate-reverse both;
    animation: scale 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate-reverse both;
  }

  @keyframes scale {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .pulse {
    -webkit-animation: pulse 1s ease-in-out infinite both;
    animation: pulse 1s ease-in-out infinite both;
  }

  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .fade-out {
    opacity: 0;
  }

  .pwc-icon-left {
    left: 5%;
    transform: translateX(-5%);
    top: 40px;
    display: block;
    position: absolute;
    width: 75px;
  }

  .pwc-icon-center {
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    display: block;
    position: absolute;
    width: 100px;
  }





`;
