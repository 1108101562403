import styled from 'styled-components';
import BaseLayout from "../../components/Common/BaseLayout";
import {StyledButton} from "../../components/Common/Button/styles";
import BaseImage from '../../components/Common/BaseImage';
export const StyledNotFound = styled(BaseLayout)`
    ${StyledButton} {
      margin-top: 3.7rem;
    }
`;


export const StyledNotFoundImage = styled(BaseImage)`
    margin-top: 3.7rem;
   
`;
