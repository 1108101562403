import styled, { css } from "styled-components";
import {theme} from "./theme";
import {device} from "./mediaQueries"

export const HeadlineXlStyles = css`
  font-family: "Charter", serif;
  font-size: 4rem;
  line-height: 1.3;
  letter-spacing: 0.04rem;

  @media ${device.tablet} {
    font-size: 6rem;
  }
`
export const TextStyles = css`
  font-family: "Helvetica", sans-serif;
  font-size: 1.5rem;
  line-height: 1.3;

  @media ${device.tablet} {
    font-size: 2.3rem;
    line-height: 1.4;
  }
`

export const LinkStyles = css`
  ${TextStyles};
  display: inline-block;
  color: ${theme.colors.grey};
  text-decoration: underline;
`

export const HeadlineStyles = css`
  font-size: 1.5rem;
  line-height: 1;
  font-family: "Helvetica Bold", sans-serif;
  letter-spacing: 0.02em;

  @media ${device.tablet} {
    font-size: 2.3rem;
    letter-spacing: 0.02px;
  }
`

export const HeadlineXl = styled.h1`
  ${HeadlineXlStyles}
`

export const Headline = styled.h2`
  ${HeadlineStyles};
`

export const Text = styled.p`
  ${TextStyles};
  color: ${theme.colors.grey};
  ${props => props.bold && css`
    font-family: "Helvetica Bold", sans-serif;
  `}
`

export const Link = styled.a`
  ${LinkStyles};
`
