import { FC } from "react";
import { Img, StyledBaseImage } from "./styles";

export interface BaseImage {
    src: string
    width: number
    height: number
}


const BaseImage: FC<BaseImage> = ({src, width, height, ...others}) => {
    if(!src || !width || !height) return null

    return (
        <StyledBaseImage style={{ '--ar': height / width }} {...others}>
            <Img src={src} />
        </StyledBaseImage>
    )
};

export default BaseImage;
