import {StyledBaseLayout, BaseLayoutHeadline} from "./styles";
import Logo from "../Logo";
import {FC} from "react";
import {Link} from "react-router-dom";

interface LegalBaseLayout {
};

const LegalBaseLayout: FC<LegalBaseLayout> = ({children, ...others}) => {
    return (
        <StyledBaseLayout {...others}>
            {children}
        </StyledBaseLayout>
    );
};

export default LegalBaseLayout;
