import styled, {css, keyframes} from 'styled-components'
import BaseLayout from "../../../components/Common/BaseLayout";
import {HeadlineXl, Text} from "../../../styles/typography";
import { device } from '../../../styles/mediaQueries';

export const ButtonWrapper = styled.div`
  bottom: var(--page-padding);
  left: var(--page-padding);
  right: var(--page-padding);
  margin: 3rem auto 0;

  display: flex;
  gap: 1.3rem;


  @media ${device.tablet} {
    position: fixed;
    bottom: 4.4rem;
  }

`


const fadeIn = keyframes`
  from {
   transform: translateY(6rem);
   opacity: 0;
  }

  to {
   transform: translateY(0);
   opacity: 1;
  }
`;

const animationStyles = css`
 animation-name: ${fadeIn};
 animation-duration: 0.5s;
 animation-fill-mode: forwards;
 animation-timing-function: var(--ease-in-out-cubic);
`

export const StyledShare = styled(BaseLayout)`
padding: var(--page-padding);

 ${Text} {
  margin-top: 2.3rem;
  opacity: 0;

  @media ${device.tablet} {     
    max-width: 68rem;
  }

 }
 
 // FadeIn Animation
 ${HeadlineXl} {
  ${animationStyles};
  opacity: 0;

 }
 ${Text} {
  ${animationStyles};
  animation-delay: 0.1s;
  opacity: 0;

 }
 ${ButtonWrapper}  {
  ${animationStyles};
  animation-delay: 0.2s;
  opacity: 0;
 }
`;
