import { StyledLogo } from "./styles"
import {FC} from "react";
import storyService, {Story} from "../../../services/storyService";
import BaseImage from "../BaseImage";
import initService from "../../../services/initService";

interface Logo {
  size?: "s" | "m" | "l"
  type?: "color" | "white"
}

const Logo: FC<Logo> = ({size = "m", type= "color"}) => {
  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getSelectedStory()

  return (
      <StyledLogo size={size} to={story ? `/${story.urlName}` : "/"}>
        <BaseImage
            src={type ==="color" ? process.env.PUBLIC_URL + "/res/img/PricewaterhouseCoopers_Logo.png" : process.env.PUBLIC_URL + "/res/img/PWC_Logo_White.png"}
            width={type ==="color" ? 316 : 75}
            height={type ==="color" ? 240 : 61}
        />
      </StyledLogo>
  );
}

export default Logo
