export const theme = {
	colors: {
		black: '#000',
		grey: '#707070',
		greyLight: '#F3F3F3',
		white:'#FFFFFF',
		whiteDark:'#F3F3F3',
		orange: '#D04A02',
		tangerine: '#EB8C00',

		//Old
		red: '#ff0000',
		lightGrey: '#f2f2f2',
		lightGreen: '#90EE90'
	},
	fonts: {
		helvetica: process.env.PUBLIC_URL + '/res/fonts/HelveticaNeueLTW04-55Roman.woff2',
		helveticaBold: process.env.PUBLIC_URL + '/res/fonts/HelveticaNeueLTW04-75Bold.woff2',
		charter: process.env.PUBLIC_URL + '/res/fonts/CharterITCW05-Regular.woff2',

		brandomGrotesqueBold: process.env.PUBLIC_URL + '/res/fonts/BrandonGrotesque-Bold.woff2',
		gothamBold: process.env.PUBLIC_URL +'/res/fonts/Gotham-Bold.woff2'
	}
}
