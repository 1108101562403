import { StyledNotFound, StyledNotFoundImage } from './styles';

const NotFound = () => {
  return (
    <StyledNotFound headline="Leider können wir zur Zeit nicht auf die Website zugreifen...    ">
        <StyledNotFoundImage src={process.env.PUBLIC_URL + "/res/img/newWorldNewSkills.png"} width={512} height={512} />
    </StyledNotFound>
  );
};

export default NotFound;
