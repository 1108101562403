import styled from "styled-components";
import {HeadlineXl} from "../../../styles/typography";
import {theme} from "../../../styles/theme";

export const StyledBaseLayout = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: var(--page-padding);
  background-color: ${theme.colors.white};
`;

export const BaseLayoutHeadline = styled(HeadlineXl)`
  margin-top: 3.7rem;
`;
