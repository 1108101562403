import { useEffect, useRef, FC, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import ArScene from '../arScene';
import arService from '../../services/arService';
import { loadingApi } from '../../services/loadingService';
import initService from '../../services/initService';
import TouchContainer from '../touchContainer';
import {useLocation} from 'react-router-dom';

interface ArCanvas {
}

const ArCanvas: FC<ArCanvas> = () => {
  const active = arService(state => state.active)
  const canvas = useRef()
  const isInitialized = initService(state => state.initialized)

  useEffect(() => {
    if(!isInitialized) return
    
    if(canvas.current && active) {
      arService.getState().start(canvas.current)
    }
    /*
    else {
      arService.getState().stop()
      loadingApi.setState({loadingScreenIsVisible: true})
    }
    */
  }, [canvas, active, isInitialized])


  if(!isInitialized) return <></>

  //console.log("render ar canvas | visible =", active)

  return (
    <Suspense fallback={null}>
      <TouchContainer>
        <Canvas
          ref={canvas}
          onContextMenu={e => {
            e.nativeEvent.preventDefault();
          }}
          style={{ width: '100%', height: '100%', zIndex: -20, position: 'absolute', visibility: active ? 'visible' : 'hidden'}}
          frameloop={"never"}
        >
          <ArScene />
        </Canvas>
      </TouchContainer>
    </Suspense>
  )
}

export default ArCanvas;