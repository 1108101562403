import styled from 'styled-components'
import {StyledButton} from "../Common/Button/styles";
import {Text} from "../../styles/typography";
import {theme} from "../../styles/theme";
import {device} from "../../styles/mediaQueries";

export const StyledInteractionProgress = styled(Text)`
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: ${theme.colors.white};
`;

export const StyledArFooter = styled.div`
  position: fixed;
  bottom: 2.5rem;
  left: var(--page-padding);
  right: var(--page-padding);
  z-index: 2;

  @media ${device.tablet} {
    bottom: var(--page-padding);
  }
`;

export const StyledPlaceComponent = styled.div`
  display: flex;
  gap: 1.3rem;
  
  ${StyledButton} {
    &:last-child {
      flex-grow: 1;
    }
  }
`
