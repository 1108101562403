import styled, {css} from "styled-components";
import {device} from "../../styles/mediaQueries";
import {theme} from "../../styles/theme";

export const StyledArNotification = styled.div`
  position: fixed;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);

  width: calc(100% - 3rem);
  z-index: 2;
  padding: 2rem;
  background-color: ${theme.colors.greyLight};
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  text-align: center;

  transition: opacity 0.3s linear, transform 0.6s var(--ease-in-out-quart);
  ${props => !props.isVisible && css`
		opacity: 0;
		pointer-events: none;
		transform: translate(-50%, -100%);
	`}

  @media ${device.tablet} {
    top: 3.7rem;
    width: 43rem;
  }
`;
