import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';
import { theme } from '../../styles/theme';

export const StyledOrientationInfo = styled.div`
  background-color: ${theme.colors.white};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 850;
`

export const StyledLogo = styled.img`
    display: inline-block;
    margin-top: 2.5%;
    margin-left: 2.5%;
    width: 8rem;

`

export const StyledIcon = styled.img`

    padding: 2rem;
    width: 12.5rem;
    margin: 0 auto;

  
`

export const StyledText = styled.div`
      font-family: "Charter", serif;
      font-size: 2.7rem;
      line-height: 1.3;
      letter-spacing: 0.04rem;
      max-width: 38rem;
      margin: 0 auto;

     
  
  `

export const ItemContainer = styled.div`
  max-width: 80%;
  display: flex;
  
  margin: 0 auto;

  @media ${device.tablet} {
  }
  
  `


export const MainContainer = styled.div`
top: 0;
left: 0;
position: fixed;
height: 100%;
width: 100%;

display: flex;
align-items: center;
justify-content: center;

`
