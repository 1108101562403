import { useEffect, useRef, useState } from 'react'
import { Object3D, Texture, Color } from 'three'
import sceneService from '../../../services/sceneService'
import interactionService from '../../../services/interactionService'
import {Text} from "@react-three/drei"

interface PointOfInterest {
  id: number
  yOffset: number
  text: string
}

const ICON_SIZE = 0.05

const PointOfInterest: Object3D<PointOfInterest> = ({id, yOffset, text}) => {
  const camera = sceneService(state => state.camera)
  const groupRef = useRef<Object3D>()
  const interacted = interactionService(state => state.required.filter(entry => entry.id === id))
  const [icon, setIcon] = useState<Texture>(sceneService.getState().textures.get("ArrowIcon"))

  useEffect(() => {
    if(!interacted[0] || !interacted[0].interacted) return

    setTimeout(() => {setIcon(sceneService.getState().textures.get("CheckIcon"))}, 1000)
  }, [interacted])

  useEffect(() => {
    requestAnimationFrame(update)
  }, [])

  const update = () => {
    requestAnimationFrame(update)

    if(!groupRef.current || !camera) return
    
    groupRef.current.lookAt(camera.position)
  }

  return (
    <group ref={groupRef} position={[0.0, yOffset + 0.15, 0.0]}>
      <mesh position={[ICON_SIZE * -0.8 + text.length * -0.005, 0.0, 0.0]}>
        <planeBufferGeometry args={[ICON_SIZE, ICON_SIZE]}/>
        <meshBasicMaterial map={icon} transparent={true}/>
      </mesh>
      {/*@ts-expect-error*/}
      <Text color="white" anchorX="left" anchorY="middle" fontSize={0.035} font={window.location.origin + process.env.PUBLIC_URL + "/res/fonts/HelveticaNeue-Bold.woff"} position={[text.length * -0.005, 0.0, 0.0]} outlineWidth={0.0005} outlineBlur={0.009} outlineOpacity={0.5} outlineColor={new Color(0x000000)}>
        {text}
      </Text>
    </group>
  )
}

export default PointOfInterest