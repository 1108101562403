import { useParams, useNavigate } from 'react-router-dom';
import { ScrollWrapper, ContentWrapper, ButtonWrapper, SubHeadline, Li, Ul } from './styles';
import { Button } from '../../components/Common/Button';
import storyService, { Story } from '../../services/storyService';
import {Headline, HeadlineXl, Link, Text} from "../../styles/typography";
import initService from '../../services/initService';
import LegalBaseLayout from '../../components/Common/LegalBaseLayout';

const PrivacyPolicy = () => {
  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getSelectedStory()

  return (
    <LegalBaseLayout >
          <ScrollWrapper>
            <ContentWrapper>
              <HeadlineXl>Datenschutz&shy;erklärung</HeadlineXl>
              <SubHeadline>Datenschutzhinweise nach Art. 13, 14 EU Datenschutz&shy;grundverordnung (DSGVO)</SubHeadline> 
              <Text>Die nachfolgenden Datenschutz&shy;hinweise sollen Ihnen verständlich, transparent und übersichtlich erläutern, wie wir, die Pricewaterhouse&shy;Coopers GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft (im Folgenden: „PwC WPG“), Ihre personenbezogenen Daten im Zusammenhang mit Ihrer Nutzung unserer Webseiten, Applikationen („Apps“) und Online-Services verarbeiten. Sollten Sie dennoch Verständnisfragen oder sonstige Rückfragen zum Datenschutz bei PwC haben, können Sie sich gerne an unseren Datenschutzbeauftragten wenden und diesen unter DE_Datenschutz@pwc.com oder den weiteren, unten angegebenen Kontaktdaten kontaktieren.</Text>
              
              <SubHeadline>1. Verantwortliche</SubHeadline>
              <Text>Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO für die Verarbeitung Ihrer personenbezogenen Daten ist die:
              Pricewaterhouse&shy;Coopers GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft
              Friedrich-Ebert-Anlage 35-37
              60327 Frankfurt am Main
              E-Mail: DE_Kontakt@pwc.com
              Telefonzentrale: +49 69 9585-0
              Fax: +49 69 9585-1000</Text>

              <SubHeadline>2. Datenschutz&shy;beauftragter</SubHeadline>
              <Text>PwC WPG hat einen Datenschutz&shy;beauftragten gemäß Art. 37 DSGVO benannt. Sie können den Datenschutz&shy;beauftragten der PwC WPG, Dr. Tobias Gräber, unter den folgenden Kontaktdaten erreichen:
              E-Mail-Kontakt: DE_Datenschutz@pwc.com<br />
              Telefon: +49 69 9585-0<br />

              Adresse für postalische Kontaktaufnahme:

              PricewaterhouseCoopers GmbH WPG<br />
              Dr. Tobias Gräber, Datenschutzbeauftragter<br />
              Friedrich-Ebert-Anlage 35-37<br />
              60327 Frankfurt am Main</Text>
              
              <SubHeadline>3. Betroffenenrechte/&shy;Ihre Rechte aus dem Datenschutzrecht</SubHeadline>
              <Text>Sie haben folgende Rechte nach dem geltenden Datenschutzrecht hinsichtlich der Sie betreffenden personenbezogenen Daten.</Text>
              <Text><b>Recht auf Auskunft:</b> Sie können jederzeit von uns Auskunft darüber verlangen, ob und welche personenbezogenen Daten wir über Sie speichern. Die Auskunftserteilung ist für Sie kostenfrei.<br /><br />Das Recht auf Auskunft besteht nicht oder nur eingeschränkt, wenn und soweit durch die Auskunft geheimhaltungsbedürftige Informationen offenbart würden, bspw. Informationen, die einem Berufsgeheimnis unterliegen.</Text>
              <Text><b>Recht auf Berichtigung:</b> Wenn Ihre personenbezogenen Daten, die bei uns gespeichert sind, unrichtig oder unvollständig sind, haben Sie das Recht, von uns jederzeit die Berichtigung dieser Daten zu verlangen.</Text>
              <Text><b>Recht auf Löschung:</b> Sie haben das Recht, von uns die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn und soweit die Daten für die Zwecke, für die sie erhoben wurden, nicht mehr benötigt werden oder, wenn die Verarbeitung auf Ihrer Einwilligung beruht, Sie Ihre Einwilligung widerrufen haben. In diesem Fall müssen wir die Verarbeitung Ihrer personenbezogenen Daten einstellen und diese aus unseren IT-Systemen und Datenbanken entfernen.</Text>
              <Text>Ein Recht auf Löschung besteht nicht, soweit.</Text>

              <Ul>
              <Li ><Text>die Daten aufgrund einer gesetzlichen Pflicht nicht gelöscht werden dürfen oder aufgrund einer gesetzlichen Pflicht verarbeitet werden müssen;</Text></Li>
              <Li ><Text>die Datenverarbeitung erforderlich ist zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</Text></Li>
              </Ul>

              
            </ContentWrapper>

            { <ButtonWrapper>
                  <Button url={story ? `/${story.urlName}` : "/"} text={'Zurück zum Start'} />
              </ButtonWrapper>
            }
          </ScrollWrapper>
      </LegalBaseLayout>
  );
};

export default PrivacyPolicy;
