import { FC } from "react";
import {StyledBaseVideo, Video} from "./styles";

interface BaseVideo {
	src: string
	width: number
	height: number
	options?: {
		autoPlay?: boolean
		muted?: boolean
		playsInline?: boolean
		loop?: boolean
	}
  videoRef: any
};

const BaseVideo: FC<BaseVideo> = ({src, width, height, videoRef = null, options = {}}) => {
	if(!src || !width || !height) return null
	const {autoPlay = true, muted = true, playsInline = true, loop = false } = options

	return (
		<StyledBaseVideo style={{ '--ar': height / width }}>
			<Video ref={videoRef} src={src} muted={muted} playsInline={playsInline} autoPlay={autoPlay} loop={loop} />
		</StyledBaseVideo>
	)
};

export default BaseVideo;
