import arService from "../services/arService"
import interactionService from "../services/interactionService"
import sceneService from "../services/sceneService"

declare let XR8: any;

export const reset = () => {
  if(!arService.getState().started) return

  interactionService.getState().reset()
  sceneService.getState().reset()

  XR8.XrController.recenter()
}