import styled from 'styled-components';

export const StyledLogo = styled.img`
left: 50%;
transform: translateX(-50%);
bottom: 17%;

display: block;
position: absolute;
width: 150px;
`;