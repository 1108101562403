import styled from "styled-components";
import {HeadlineXl, Headline} from "../../../styles/typography";
import {theme} from "../../../styles/theme";
import BaseLayout from "../BaseLayout";
import { device } from "../../../styles/mediaQueries";
import { StyledButton } from "../Button/styles";
import { StyledTabs } from "../Tabs/styles";

export const StyledBaseLayout = styled.div`
position: fixed;
top: 0;
left: 0;
z-index: 5;
height: 100%;
width: 100%;
overflow: hidden;
background-color: ${theme.colors.white};
z-index: 900;
`;

export const BaseLayoutHeadline = styled(HeadlineXl)`
  margin-top: 0rem;
`;
