import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {FC, useEffect} from 'react';
import storyService, { Story } from '../../../services/storyService';
import initService from '../../../services/initService';
// import permissionService from '../../../services/permissionService';
import Inspect from '../../../components/story/inspect';
import ArFooter from '../../../components/arFooter';
import Logo from "../../../components/Common/Logo";
import {LogoWrapper} from "./styles";
import ArNotification from "../../../components/arNotification";
import arService from '../../../services/arService';
import LoadingScreen from '../../../components/arCanvas/loading-module/loadingScreen';
import interactionService from '../../../services/interactionService';
import sceneService from '../../../services/sceneService';

interface Ar {
}

const Ar: FC<Ar> = () => {
  const navigate = useNavigate()

  const { storyUrl } = useParams();
  const isInitialized = initService(state => state.initialized)

  useEffect(() => {
    arService.setState({active: true})
    return () => {
      arService.setState({active: false})
    }
  }, [])

  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().selectStory(storyUrl)

  if(!story) navigate('/not-found')
  // if(!permissionService.getState().permission.acceptCookie) navigate(`/${story.urlName}`)

  return (
    <>
      <LogoWrapper>
        <Logo size="s" type="white" />
      </LogoWrapper>
      <LoadingScreen />

      <ArNotification text={"Sobald Sie das Objekt platziert haben, können Sie sich frei bewegen. Tippen Sie auf die Modelle der einzelnen Themen, um sie zu erkunden - viel Spaß!"} />
      <ArFooter story={story} />

      {story.content.interactives.map(interactive => <Inspect interactive={interactive} /> )}
    </>
  )
};

export default Ar;
