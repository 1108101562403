import styled from 'styled-components';
import {Link} from "react-router-dom";
import {device} from "../../../styles/mediaQueries";

export const StyledLogo = styled(Link)`
  display: inline-block;
  width: ${props => props.size === "l" ? "10.6rem" : props.size === "m" ? "9.2rem" : "6.6rem"};


  @media ${device.tablet} {
    width: ${props => props.size === "l" ? "16rem" : props.size === "m" ? "12.5rem" : "12.5rem"};
  }
`;
