import styled, {css} from 'styled-components';
import {StyledButton} from "../../Common/Button/styles";
import InspectBaseLayout from "../../Common/InspectBaseLayout";
import {theme} from "../../../styles/theme"
import {device} from "../../../styles/mediaQueries"
import {StyledTabs} from "../../Common/Tabs/styles";
import {HeadlineXl, Text} from "../../../styles/typography";
import BaseVideo from '../../Common/BaseVideo';
import Logo from '../../Common/Logo';

export const ScrollWrapper = styled(InspectBaseLayout)`
  position: relative;
  max-height: 100%;
  overflow-y: scroll;
`

export const StyledInspect = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${theme.colors.white};
  
  transform: translateX(105%);
  transition: transform 0.8s var(--ease-in-out-quart);
  
  ${props => props.isVisible && css`
    transform: translateX(0);
  `}
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - var(--page-padding) - var(--page-padding));
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    bottom: var(--page-padding);
  }
  
  ${StyledButton} {
    //Glow 
    &:before {
      content: "";
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 97%;
      height: 60%;
      box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
      filter: blur(13px);
      background-color: ${theme.colors.orange};
      z-index: -1;
    }
  }
`

export const ContentWrapper = styled.div`
  margin-top: 0rem;
  margin-bottom: 8rem;
  display: grid;
  gap: 2rem;
  
  ${StyledTabs} {
    margin-top: 0rem;
  }

  ${BaseVideo} {
    margin-top:0rem;
  }

  ${HeadlineXl} {
    margin-top: 0rem;
  }
  

  @media ${device.tablet} {
    margin-top: 0rem;
    margin-bottom: 10rem;
    gap: 4rem;
    
    ${Text},
    ${HeadlineXl} {
      max-width: 64rem;

    }

    ${BaseVideo} {
      max-width: 68rem;
      margin-top:0rem;
    }
  }
`
