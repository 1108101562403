import { FC, useEffect, useState, useRef, Suspense } from 'react';
import storyService from '../../services/storyService';
import { useThree } from '@react-three/fiber';
import { Vector2, Raycaster, Object3D, Color, DirectionalLight} from 'three'
import sceneService from '../../services/sceneService';
import interactionService from '../../services/interactionService';
import debugService from '../../services/debugService';

interface ArScene {
}

const VIEWPORT_CENTER: Vector2 = new Vector2(0, 0)
const MODEL_SCALE = 3.2

const ArScene: FC<ArScene> = () => {
  const sceneRef = useRef<Object3D>()
  const groundRef = useRef<Object3D>()
  const modelRef = useRef<Object3D>()
  const lightRef = useRef<DirectionalLight>()

  const { set } = useThree()

  const objects = sceneService((state) => state.objects)

  const scene = sceneService((state) => state.scene)
  const camera = sceneService((state) => state.camera)
  const [ customRaycaster ] = useState<Raycaster>(new Raycaster())

  const selectedStoryUrlName = storyService(state => state.selectedStoryUrlName)

  // position and rotate main model to look position
  const update = () => {
    const positionModelToView = () => {
      if(!scene || !camera || !groundRef.current || !modelRef.current) return

      customRaycaster.setFromCamera(VIEWPORT_CENTER, camera)

      const intersects = customRaycaster.intersectObject(groundRef.current, true)
  
      if (intersects.length < 1) return
  
      modelRef.current.position.set(intersects[0].point.x, 0.0, intersects[0].point.z);
    }

    const rotateModelToView = () => {
      if(!scene || !camera || !modelRef.current) return

      modelRef.current.rotation.y = Math.atan2( ( camera.position.x - modelRef.current.position.x ), ( camera.position.z - modelRef.current.position.z ) );
    }

    requestAnimationFrame(update)

    if(interactionService.getState().mode === "PLACE") {
      rotateModelToView()
      positionModelToView()
    }
  }

  useEffect(() => {
    if(!selectedStoryUrlName) return

    sceneService.getState().load(storyService.getState().getSelectedStory())
  }, [selectedStoryUrlName])

  useEffect(() => {
    if(!camera) return
    set({camera})
  }, [camera])

  // add r3f scene to 8th wall vanilla three scene
  useEffect(() => {
    if(!scene || !sceneRef.current) return
    scene.add(sceneRef.current)
  }, [scene, sceneRef])

  useEffect(() => {
    if(!lightRef.current) return

    lightRef.current.shadow.camera.near = 0.1
    lightRef.current.shadow.camera.far = 10
  }, [lightRef])

  useEffect(() => {
    if(!scene || !camera) return

    requestAnimationFrame(update)
  }, [scene, camera])

  if(!scene || !camera) return <></>

  //console.log("render ar scene")

  return (
      <group ref={sceneRef}>
        <directionalLight position={[0.0, 5.0, 0.0]} ref={lightRef} color={0xffffff} intensity={1.0} castShadow={true}/>
        {/* ground plane for raycast intersection tests */}
        <mesh ref={groundRef} rotation={[-Math.PI * 0.5, 0.0, 0.0]} visible={debugService.getState().debugEnabled}>
          <planeBufferGeometry args={[10.0, 10.0, 8.0, 8.0]}/>
          <meshBasicMaterial color={new Color(0xff00ff)} wireframe={true}/>
        </mesh>
        {/* main model group */}
        <group ref={modelRef} scale={[MODEL_SCALE, MODEL_SCALE, MODEL_SCALE]}>
          {objects.static.map(object => object)}
          {objects.interactive.map(object => object)}      
          <mesh rotation={[-Math.PI * 0.5, 0.0, 0.0]} receiveShadow={true}>
            <planeBufferGeometry args={[1.0, 1.0, 1.0, 1.0]}/>
            <shadowMaterial opacity={0.4}/>
          </mesh>
        </group>
      </group>
  )
}

export default ArScene;