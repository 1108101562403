import {ScrollWrapper, ContentWrapper, ButtonWrapper, SubHeadline, Li, Ul } from './styles';
import { Button } from '../../components/Common/Button';
import storyService, { Story } from '../../services/storyService';
import {Headline, HeadlineXl, Link, Text} from "../../styles/typography";
import initService from '../../services/initService';
import { StyledTabs } from '../../components/Common/Tabs/styles';
import LegalBaseLayout from '../../components/Common/LegalBaseLayout';


const Impressum = () => {
  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getSelectedStory()

  return (
    <LegalBaseLayout >
          <ScrollWrapper>
            <ContentWrapper>
               <HeadlineXl>Impressum</HeadlineXl>
              <Text>Unternehmens&shy;angaben nach § 5 Tele&shy;mediengesetz (TMG) und §§ 2,3 Dienst&shy;leistungs-Informations&shy;pflichten-Verordnung (DL-InfoV) sowie § 18 Abs. 2 Medienstaats&shy;vertrag (MStV).</Text>
              <SubHeadline>Dieses Impressum gilt auch für die Darstellung unseres Unternehmens auf:</SubHeadline>
              <Ul>

              <Li ><Text>Facebook Career: </Text><Link href={"https://www.facebook.com/pwc.career"} target="_blank" rel="noopener">{<Text>https://www.facebook.com/<wbr />pwc.career</Text>}</Link></Li>
              <Li ><Text>Twitter:</Text> <Link href={"https://www.twitter.com/pwc_de"} target="_blank" rel="noopener">{<Text>https://www.twitter.com/<wbr />pwc_de</Text>}</Link></Li>
              <Li ><Text>Xing:</Text> <Link href={"https://www.xing.com/company/pwcdeutschland"} target="_blank" rel="noopener">{<Text>https://www.xing.com/company/<wbr />pwcdeutschland</Text>}</Link></Li>
              <Li ><Text>Youtube:</Text> <Link href={"https://www.youtube.com/user/pwcde"} target="_blank" rel="noopener">{<Text>https://www.youtube.com/user/<wbr />pwcde</Text>}</Link></Li>
              <Li ><Text>Youtube Career:</Text> <Link href={"https://www.youtube.com/user/pwccareerde"} target="_blank" rel="noopener">{<Text>https://www.youtube.com/user/<wbr />pwccareerde</Text>}</Link></Li>
              <Li ><Text>LinkedIn:</Text> <Link href={"https://www.linkedin.com/company/pwc-deutschland"} target="_blank" rel="noopener">{<Text>https://www.linkedin.com/company/<wbr />pwc-deutschland</Text>}</Link></Li>
              <Li ><Text>LinkedIn Career:</Text> <Link href={"https://www.linkedin.com/showcase/pwc-karriere"} target="_blank" rel="noopener">{<Text>https://www.linkedin.com/showcase/<wbr />pwc-karriere</Text>}</Link></Li>
              <Li ><Text>LinkedIn Alumni:</Text> <Link href={"https://www.linkedin.com/showcase/pwc-alumni-deutschland"} target="_blank" rel="noopener">{<Text>https://www.linkedin.com/showcase/<wbr />pwc-alumni-deutschland</Text>}</Link></Li>
              <Li ><Text>Pinterest:</Text> <Link href={"https://www.pinterest.de/pwc_de"} target="_blank" rel="noopener">{<Text>https://www.pinterest.de/<wbr />pwc_de</Text>}</Link></Li>
              <Li ><Text>Instagram:</Text> <Link href={"https://www.instagram.com/pwc_de"} target="_blank" rel="noopener">{<Text>https://www.instagram.com/<wbr />pwc_de</Text>}</Link></Li>
              <Li ><Text>Medium:</Text> <Link href={"https://sustainableinnovator.medium.com/"} target="_blank" rel="noopener">{<Text>https://sustainableinnovator.medium.com/</Text>}</Link></Li>
              </Ul>
              <Headline>{'PricewaterhouseCoopers GmbH'}</Headline>
              <Text>Wirtschafts&shy;prüfungs&shy;gesellschaft<br />Friedrich-Ebert-Anlage 35-37<br />60327 Frankfurt am Main</Text>

              <Headline>{'Vorsitzender des Aufsichtsrats:'}</Headline>
              <Text>{'WP StB Dr. Norbert Vogelpoth'}</Text>

              <Headline>{'Mitglieder der Geschäftsführung:'}</Headline>

              <Text>WP StB Dr. Ulrich Störk • WP StB Dr. Peter Bartels • Dr. Joachim Englert • WP StB Petra Justenhoven • WP Clemens Koch • StB Marius Möller • WP StB Uwe Rittmann • StB RA Klaus Schmidt</Text>
              <Text>E-Mail: DE_Kontakt@pwc.com<br />Telefonzentrale: +49 69 9585-0<br />Fax: +49 69 9585-1000</Text>


              <Headline>{'Aufsichtsbehörde:'}</Headline>

              <Text>letztverantwortlich: Abschluss&shy;prüfer&shy;aufsichts&shy;stelle (APAS),<br />Uhlandstraße 88-90, 10717 Berlin, Deutschland;<br /> Wirtschaftsprüferkammer (WPK),Körperschaft des öffentlichen Rechts,<br />Rauchstraße 26, 10787 Berlin, Deutschland<br /></Text>
              <Text>Sitz: Frankfurt am Main • Amtsgericht Frankfurt am Main HRB 107858</Text>
              <Text>Die Pricewaterhouse&shy;Coopers GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft ist eine in Deutschland von der Wirtschafts&shy;prüferkammer zugelassene und im Berufsregister unter der laufenden Nummer 150 9 337 eingetragene Wirtschafts&shy;prüfungs&shy;gesellschaft.</Text>


<Headline>{'Berufsrechtliche Regelungen:'}</Headline>
<Ul>

<Li ><Text>Wirtschaftsprüferordnung (WPO)</Text></Li>
<Li ><Text>Berufssatzung für Wirtschaftsprüfer/&shy;vereidigte Buchprüfer (BS WP/vBP)</Text></Li>
<Li ><Text>Satzung für Qualitätskontrolle</Text></Li>
</Ul>

<Text>Informationen zu diesen Regelungen finden Sie auf der Internetseite der Wirtschafts&shy;prüferkammer: <Link href={"http://www.wpk.de/"} target="_blank" rel="noopener">{<Text>http://www.wpk.de/</Text>}</Link>.<br /></Text>


<Headline>{'Umsatzsteueridentifikations-Nr.:'}</Headline>
<Text>{'DE194821795'}</Text>

<Headline>{'Berufshaftpflichtversicherung:'}</Headline>
<Text>Die Pricewaterhouse&shy;Coopers GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft unterhält die nach § 54 WPO erforderliche Berufshaft&shy;pflicht&shy;versicherung bei der VSW – Die Versicherer&shy;gemeinschaft für Steuerberater und Wirtschaftsprüfer, Dotzheimer Str. 23, 65185 Wiesbaden, Deutschland. Der räumliche Geltungs&shy;bereich des Versicherungs&shy;schutzes ist nicht beschränkt.</Text>


<Headline>{'Verhaltenskodizes:'}</Headline>
<Text>Die Pricewaterhouse&shy;Coopers GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft hat sich folgenden Verhaltenskodizes unterworfen:</Text>
<Ul>
<Li ><Text>PwC-Ethik-Grundsätze/PwC Code of Conduct (zum Download in deutscher Sprache zugänglich über <Link href={"http://www.pwc.de/de/ethikcode"} target="_blank" rel="noopener">{<Text>http://www.pwc.de/de/ethikcode</Text>}</Link>)</Text></Li>
<Li ><Text>Zehn Prinzipien von UN Global Compact (in deutscher und englischer Sprache zugänglich über <Link href={"http://www.globalcompact.de/"} target="_blank" rel="noopener">{<Text>http://www.globalcompact.de/</Text>}</Link>)</Text></Li>
</Ul>

<StyledTabs />
<SubHeadline>Tochter&shy;gesellschaften der Pricewaterhouse&shy;Coopers GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft:</SubHeadline>
<Ul>
<Li ><Text>BDK Corporate Finance Beratung GmbH</Text></Li>
<Li ><Text>DRT Wirtschafts&shy;beratungs- und Treuhand&shy;gesellschaft mbH</Text></Li>
<Li ><Text>Fachverlag Moderne Wirtschaft GmbH</Text></Li>
<Li ><Text>INTES Akademie für Familienunternehmen GmbH</Text></Li>
<Li ><Text>kampus GmbH</Text></Li>
<Li ><Text>PwC Strategy& (Germany) GmbH</Text></Li>
<Li ><Text>PwC Strategy& (Austria) GmbH</Text></Li>
<Li ><Text>PwC Cyber Security Services GmbH</Text></Li>
<Li ><Text>PwC Certification Services GmbH</Text></Li>
<Li ><Text>PwC Europe Advisory GmbH</Text></Li>
<Li ><Text>PricewaterhouseCoopers Corporate Finance Beratung GmbH</Text></Li>
<Li ><Text>PwC Solutions GmbH</Text></Li>
<Li ><Text>PwC cundus AG</Text></Li>
<Li ><Text>PwC FS Tax GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft</Text></Li>
<Li ><Text>PwC Treuhand GmbH Wirtschafts&shy;prüfungs&shy;gesellschaft</Text></Li>
<Li ><Text>WIBERA Wirtschafts&shy;beratung AG Wirtschafts&shy;prüfungs&shy;gesellschaft</Text></Li>
</Ul>

        <Headline>{'Außergerichtliche Streitbeilegung:'}</Headline>
        <Text>Gemäß unserer Verpflichtung nach § 36 Abs. 1 Verbraucher&shy;streit&shy;beilegungs&shy;gesetz (VSBG) erklären wir, dass wir weder bereit, noch verpflichtet sind, an einem Streit&shy;beilegungs&shy;verfahren vor einer Verbraucher&shy;schlichtungs&shy;stelle im Sinne des § 2 VSGB teilzunehmen. Gemäß unserer Verpflichtung aus der Verordnung über Online-Streit&shy;beilegung in Verbraucher&shy;angelegenheiten weisen wir darauf hin, dass die Plattform der EU zur außergerichtlichen Online-Streitbeilegung unter folgendem Link zu erreichen ist: <Link href={"http://ec.europa.eu/consumers/odr/"} target="_blank" rel="noopener">{<Text>http://ec.europa.eu/consumers/odr/</Text>}</Link>.</Text>
        <Text>{'Sie können unter der E-Mail-Adresse DE_Kontakt@pwc.com mit uns in Kontakt treten.'}</Text>

        <Headline>Journalistisch-redaktionell Verantwortlicher i.S.d. § 18 Abs. 2 Medienstaatsvertrag (MStV):</Headline>
        <Text>Dr. Torsten Tragl<br />Pricewaterhouse&shy;Coopers GmbH<br />Wirtschaftsprüfungs&shy;gesellschaft<br />Friedrich-Ebert-Anlage 35-37<br />60327 Frankfurt am Main<br /></Text>


               
            </ContentWrapper>

            { <ButtonWrapper>
                  <Button url={story ? `/${story.urlName}` : "/"} text={'Zurück zum Start'} />
              </ButtonWrapper>
            }
          </ScrollWrapper>
    </LegalBaseLayout>
  );
};

export default Impressum;
