import styled from 'styled-components';

export const TouchArea = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  pointer-events: ${props => props.active ? "auto" : "none"};
`;
